import * as React from "react"

const Button = ( props ) => {
    
    var Bgcolor = props.bgcolor || 'bg-[#FF7070]'
    var BgcolorHover = props.bgcolorHover || 'hover:bg-primary'
    var TextColor = props.textcolor || 'text-black'
    var TextColorHover = props.textcolorHover || 'hover:text-black'
    var Width = props.width || ''

    var className = Bgcolor + ' ' + BgcolorHover + ' ' + TextColor + ' ' + TextColorHover + ' ' + Width + ' py-3 px-6 rounded-2xl text-xs uppercase'

    return (
        <button className={className}>
            {props.text}
        </button>
    )
}

export default Button