import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../components/button'
import Helmet from 'react-helmet'
import useTranslations from "../components/useTranslations"

const PlayPage = () => {

    const {
        meet_your_match,
        whether_you_seeking,
        new_dating_site
    } = useTranslations()

    return (
        <div>
            <Helmet title='Lifestyle Fever | The Ultimate Social App for Alternative Lifestyle' />
            <div className="bg-gradient-to-r from-purple-500 to-pink-500 md:h-[600px]">
                <div className="container h-full grid md:grid-cols-3 items-center">
                    <div className="text-center md:col-span-2">
                        <StaticImage 
                            src='../images/site/lsf_mobile_app2.png'
                            alt='LSF Mobile App'
                            size={80}
                            height={600}
                            layout="constrained"
                        />
                    </div>
                    <div className="text-center text-white">
                        <h6 className='text-white text-4xl mb-6'>Matching  & Sharing</h6>
                        <h1 className='uppercase'>{meet_your_match}</h1>
                        <p>
                            {whether_you_seeking}
                        </p>
                        <p className="mt-12">
                            <a href="https://lifestylefever.com" rel="noreferrer" target="_blank">
                                <Button text='Go Play' width='w-1/2' bgcolor='bg-[#111]' bgcolorHover='hover:bg-black' textcolor='text-white' textcolorHover='hover:text-white'></Button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container p-6 md:p-12 bg-base relative">
                <h1 className='text-center text-skin-primary'>
                    {new_dating_site}
                </h1>
            </div>
        </div>
    )
}

export default PlayPage